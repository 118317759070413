import { BrokerOfRecord } from '@newfront-insurance/account-api';
import { AuthRole } from '@newfront-insurance/auth-api';
import type { BadgeProps } from '@newfront-insurance/core-ui';
import { IconCircleInformation, SingleObjectSelector, Tooltip, useFuzzy } from '@newfront-insurance/core-ui';
import { cn } from '@newfront-insurance/core-ui/v2';
import type { RouterQueryOutput } from '@newfront-insurance/data-layer-server';
import { useProvider } from '@newfront-insurance/react-provision';
import debounce from 'lodash/debounce';
import { useCallback, useRef, useState } from 'react';

import type { SharedRouter } from '../../../../bff';
import { AdminTRPCProvider } from '../../../../shared/providers/trpc';
import { AccountCard } from '../../components/account-card';
import { useShouldShowProgramTypeByAccount } from '../../hooks';

export type Account = RouterQueryOutput<SharedRouter, 'accounts.getManyPublicAccounts'>[number];

interface Props {
  loggedInUserUuid: string;
  userScopes: string[];
  currentAccountUuid: string;
  onSelectAccount: (accountUuid: string) => void;
  hideTestAccountBadge?: boolean;
  selectorContainerClassName?: string;
}

export function AccountSwitcher({
  loggedInUserUuid,
  userScopes,
  currentAccountUuid,
  onSelectAccount,
  hideTestAccountBadge = false,
  selectorContainerClassName,
}: Props): JSX.Element | null {
  const { useQuery } = useProvider(AdminTRPCProvider);

  const { accounts, search, isLoading } = useAccountSwitcherOptions({
    loggedInUserUuid,
    userScopes,
  });

  const shouldShowProgramType = useShouldShowProgramTypeByAccount(accounts);

  const { data: selectedAccount, isLoading: isLoadingAccount } = useQuery([
    'accounts.getPublicByUuid',
    { accountUuid: currentAccountUuid },
  ]);
  const testAccountBadge: BadgeProps = { type: 'info', content: 'Test account' };

  const selectorContainerRef = useRef<HTMLDivElement>(null);

  const currentSelectorContainer = selectorContainerRef.current;

  return (
    <div className="flex items-center gap-0">
      <div className={cn('w-[250px]', selectorContainerClassName)} ref={selectorContainerRef}>
        <SingleObjectSelector<Account>
          popoverPortalElement={currentSelectorContainer ?? undefined}
          popoverWidth={currentSelectorContainer?.clientWidth ? `${currentSelectorContainer.clientWidth}px` : undefined}
          isClearable={false}
          itemToString={(account) => account?.name ?? ''}
          items={accounts.slice(0, 50)}
          isLoading={isLoading || isLoadingAccount}
          getItemKey={(account) => account.uuid}
          onSelect={(account) => {
            if (account) {
              onSelectAccount(account.uuid);
            }
          }}
          selectedItem={selectedAccount ?? null}
          onSearch={search}
          renderItem={(account, { isHovering, isSelected }) => (
            <AccountCard
              account={account}
              isExpandable={false}
              isHighlighted={isSelected}
              isHovering={isHovering}
              cardProps={{
                hideBorder: true,
              }}
              withProgramType={shouldShowProgramType(account.uuid)}
            />
          )}
          renderItemHover={(account) => (
            <div
              style={{
                width: 460,
              }}
            >
              <AccountCard
                account={account}
                isExpandable
                defaultExpanded
                cardProps={{
                  hideBorder: true,
                }}
                badgeProps={account?.isTestAccount ? testAccountBadge : undefined}
                withProgramType={shouldShowProgramType(account.uuid)}
              />
            </div>
          )}
          renderSelectedItemHover={(account) => (
            <div
              style={{
                width: 460,
              }}
            >
              <AccountCard
                account={account}
                isExpandable
                defaultExpanded
                cardProps={{
                  hideBorder: true,
                }}
                badgeProps={account?.isTestAccount ? testAccountBadge : undefined}
                withProgramType={shouldShowProgramType(account.uuid)}
              />
            </div>
          )}
        />
      </div>
      {!hideTestAccountBadge && selectedAccount?.isTestAccount ? (
        <Tooltip message="This is a test account. Please only use it for testing purpose.">
          <div className="flex items-center gap-2 rounded-sm px-2 py-[2px]">
            <span className="block text-sm text-glitter-500">Test Account</span>
            <IconCircleInformation />
          </div>
        </Tooltip>
      ) : undefined}
    </div>
  );
}

/**
 * Some user roles can see all accounts on the account switcher because they work across
 * all accounts and are not part of a specific servicing team. i.e. Certificates.
 */
const GLOBAL_SEARCH_ALLOWED_USER_ROLES = [
  AuthRole.AM_MANAGER,
  AuthRole.BPO_MANAGER,
  AuthRole.BPO_WORKER,
  AuthRole.BPO_TECHTAMMINA,
  AuthRole.PRODUCER_MANAGER,
  AuthRole.SALES_MANAGER,
  AuthRole.CSA,
  AuthRole.ENGINEER,
];

function canUserSearchAllAcounts(scopes: string[]): boolean {
  return GLOBAL_SEARCH_ALLOWED_USER_ROLES.some((role) => scopes.includes(role));
}

interface UseAccountSwitcherOptions {
  loggedInUserUuid: string;
  userScopes: string[];
}

export interface UseAccountSwitcherOptionsResult {
  accounts: Account[];
  search: (searchText: string) => void;
  isLoading: boolean;
}

export function useAccountSwitcherOptions({
  loggedInUserUuid,
  userScopes,
}: UseAccountSwitcherOptions): UseAccountSwitcherOptionsResult {
  const { useQuery } = useProvider(AdminTRPCProvider);
  const willSearchAllAccounts = canUserSearchAllAcounts(userScopes);

  const { data: accounts, isLoading: isLoadingAccounts } = useQuery(
    [
      'accounts.getManyPublicAccounts',
      {
        relatedUserUuid: loggedInUserUuid,
        brokerOfRecord: BrokerOfRecord.NEWFRONT,
      },
    ],
    {
      enabled: !willSearchAllAccounts,
    },
  );
  const { results: accountsForUser, search } = useFuzzy(accounts ?? [], { keys: ['name', 'dba'], debounce: 500 });

  const [globalSearchQuery, setGlobalSearchQuery] = useState('');
  const debouncedGlobalSearch = useCallback(debounce(setGlobalSearchQuery, 500), [setGlobalSearchQuery]);
  const { data: allAccounts, isLoading: isLoadingAllAccounts } = useQuery(
    [
      'accounts.search',
      {
        query: globalSearchQuery,
      },
    ],
    {
      enabled: willSearchAllAccounts,
    },
  );

  if (willSearchAllAccounts) {
    return {
      accounts: allAccounts ?? [],
      isLoading: isLoadingAllAccounts,
      search: debouncedGlobalSearch,
    };
  }

  return {
    accounts: accountsForUser,
    isLoading: isLoadingAccounts,
    search,
  };
}
