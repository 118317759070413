import type { Route } from '@newfront-insurance/next-router-provider';
import urlcat from 'urlcat';

import { getConfig } from '../config';

import type {
  AccountInvoicesQuery,
  AccountQuery,
  ApplicationBundleQuery,
  BillingQuery,
  EntityQuery,
  ExposureQuery,
  LegacyClientQuery,
  PolicyQuery,
} from '@/api/types';
import type {
  AccountCoveragesQuery,
  AccountInsurableItemsQuery,
  AccountPolicyChangesQuery,
  AccountPolicyChangesV2Query,
  CoverageGapAnalysisQuery,
  LineOfCoverageQuery,
} from '@/api/types/lib/query';
import type { WorkflowStates } from '@/client/features/account-policy-changes-v2/workflow/types';

function route<Params>(path: string) {
  return (params?: Params): string => urlcat(path, params || {});
}

function appRoute<Query extends Record<string, string | string[] | undefined>>(pathname: string) {
  return (query?: Query): Route => {
    return {
      pathname,
      query,
    };
  };
}

export const apiRoutes = {
  // Accounts
  addAccountAffiliates: route<AccountQuery>('/api/accounts/:accountUuid/add-affiliates'),
  addAffinityGroups: route<AccountQuery>('/api/accounts/:accountUuid/add-affinity-groups'),
  archiveAccount: route<AccountQuery>('/api/accounts/:accountUuid/archive'),
  canDeactivateAccount: route<AccountQuery>('/api/accounts/:accountUuid/can-deactivate'),
  createAccount: route('/api/accounts/create'),
  getPlainTextIdentities: route<AccountQuery>('/api/accounts/:accountUuid/plain-text-identities'),
  mergeAccounts: route<AccountQuery>('/api/accounts/:accountUuid/merge-into'),
  removeAccountAffiliation: route<AccountQuery>('/api/accounts/:accountUuid/remove-affiliation'),
  removeAffinityGroups: route<AccountQuery>('/api/accounts/:accountUuid/remove-affinity-groups'),
  removeSecondaryAccountRoleMapping: route<AccountQuery>(
    '/api/accounts/:accountUuid/remove-secondary-account-role-mapping',
  ),
  setAccountRoleMappings: route<AccountQuery>('/api/accounts/:accountUuid/set-account-role-mappings'),
  unarchiveAccount: route<AccountQuery>('/api/accounts/:accountUuid/unarchive'),
  updateAccount: route<AccountQuery>('/api/accounts/:accountUuid/update'),
  updateAccountLeadStatus: route<AccountQuery>('/api/accounts/:accountUuid/set-lead-status'),
  updateIdentities: route<AccountQuery>('/api/accounts/:accountUuid/update-identities'),
  getPrimaryAccountEmployees: route<AccountQuery>('/api/accounts/:accountUuid/get-primary-account-employees'),

  // Application Bundle
  getApplicationBundles: route<ApplicationBundleQuery>('/api/application-bundles'),
  createApplicationLink: route<LegacyClientQuery>('/api/application-bundles/create-application-link'),
};

/**
 * These functions will create route objects that have a pathname and a query:
 *
 *   {
 *     pathname: '/[uuid]/plan',
 *     query: {
 *       uuid: '12345',
 *       comparisonGroup: '12345,
 *     }
 *   }
 *
 * They can be rendered using the helpers in @newfront-insurance/next-router-provider to create href strings or onClick handlers.
 */
export const pageRoutes = {
  // Accounts routes
  accountDashboard: appRoute('/accounts/'),
  archivedDashboard: appRoute('/accounts/archived'),
  createAccount: appRoute('/accounts/create'),
  uploadAccountFiles: appRoute<AccountQuery>('/accounts/[accountUuid]/upload'),
  uploadPolicyFiles: appRoute<AccountQuery>('/accounts/[accountUuid]/policies/[policyUuid]/upload'),
  verifyAccount: appRoute<AccountQuery>('/accounts/[accountUuid]/verify'),
  viewAccounts: appRoute('/'),
  viewAccountBillingCorrections: appRoute<AccountQuery>('/accounts/[accountUuid]/billing-corrections'),
  viewAccountContacts: appRoute<AccountQuery>('/accounts/[accountUuid]/contacts'),
  viewAccountDetails: appRoute<AccountQuery & { openEditOnMount?: 'true' }>('/accounts/[accountUuid]'),
  viewAccountInsurableItemsDetails: appRoute<AccountInsurableItemsQuery>('/accounts/[accountUuid]/exposures'),
  viewAccountCoverages: appRoute<AccountCoveragesQuery>('/accounts/[accountUuid]/coverage'),
  viewAccountCoverageDetails: appRoute<AccountQuery & LineOfCoverageQuery>('/accounts/[accountUuid]/coverage'),
  editAccountCoverageDetails: appRoute<AccountQuery & LineOfCoverageQuery & { isEditing?: string }>(
    '/accounts/[accountUuid]/coverage',
  ),
  viewAccountPolicies: appRoute<AccountQuery>('/accounts/[accountUuid]/policies'),
  viewAccountPolicy: appRoute<AccountQuery & PolicyQuery>('/accounts/[accountUuid]/policies'),
  viewAccountPolicyChanges: appRoute<AccountPolicyChangesQuery>('/accounts/[accountUuid]/policy-changes'),
  viewAccountPolicyChangesV2: appRoute<AccountPolicyChangesV2Query>('/accounts/[accountUuid]/policy-changes'),
  policyChangeRequest: appRoute<AccountQuery & { requestUuid?: string; step?: WorkflowStates }>(
    '/accounts/[accountUuid]/policy-change-request',
  ),
  viewAccountPolicyFiles: appRoute('/accounts/[accountUuid]/policies/[policyUuid]/files'),
  viewAccountQuotingPlans: appRoute<AccountQuery>('/accounts/[accountUuid]/quoting-plans'),
  viewAccountServicingRequests: appRoute<AccountQuery>('/accounts/[accountUuid]/servicing-requests'),
  viewQuotingPlan: appRoute<EntityQuery>('/quoting/[uuid]/plan'),
  viewAccountInvoices: appRoute<AccountInvoicesQuery>('/accounts/[accountUuid]/invoices'),
  viewAccountCharges: appRoute<AccountQuery>('/accounts/[accountUuid]/account-charges'),
  viewAccountInstallmentPlans: appRoute<AccountQuery>('/accounts/[accountUuid]/installment-plans'),
  viewEbFiles: appRoute<AccountQuery>('/accounts/[accountUuid]/files'),
  viewAccountBenefitsPlans: appRoute<AccountQuery>('/accounts/[accountUuid]/benefits-plans'),
  viewAccountCensus: appRoute<AccountQuery>('/accounts/[accountUuid]/census'),
  endPolicy: appRoute<AccountQuery & PolicyQuery>('/accounts/[accountUuid]/policies/[policyUuid]/end'),

  // Account Settings routes
  viewAccountClientAccess: appRoute<AccountQuery>('/accounts/[accountUuid]/settings/client-access'),
  viewAccountClientFeatureToggles: appRoute<AccountQuery>('/accounts/[accountUuid]/settings/client-features'),
  viewAccountRelatedAccounts: appRoute<AccountQuery>('/accounts/[accountUuid]/settings/related-accounts'),
  viewAccountSalesRelationships: appRoute<AccountQuery>('/accounts/[accountUuid]/settings/sales-relationships'),
  viewAccountServicingTeam: appRoute<AccountQuery>('/accounts/[accountUuid]/settings/servicing-team'),
  viewAccountSettings: appRoute<AccountQuery>('/accounts/[accountUuid]/settings'),
  viewAccountExposures: appRoute<AccountQuery & ExposureQuery>('/accounts/[accountUuid]/exposures'),
  viewRiskPulse: appRoute<AccountQuery>('/accounts/[accountUuid]/risk-pulse'),

  // Contract review
  contractReview: appRoute<AccountQuery>('/accounts/[accountUuid]/contract-review'),

  // Coverage gap analysis
  coverageGapAnalysisWorkflow: appRoute('/coverage-gap-analysis/workflow'),
  coverageGapAnalysisDashboard: appRoute('/coverage-gap-analysis'),
  viewCoverageGapAnalysisResult: appRoute<CoverageGapAnalysisQuery>(
    '/coverage-gap-analysis/result/[coverageGapAnalysisRequestUuid]',
  ),

  // Billing
  viewBilling: appRoute<BillingQuery>('/billing'),

  // Employee web portal without app admin
  // Note: the pages that are not shared between app and portal will be removed once employee mobile app is launched
  viewAccountPortalOverview: appRoute<AccountQuery>('/accounts/[accountUuid]/overview'),
  viewAccountPortalNewHire: appRoute<AccountQuery>('/accounts/[accountUuid]/new-hire'),
  viewAccountPortalOpenEnrollment: appRoute<AccountQuery>('/accounts/[accountUuid]/open-enrollment'),
  viewAccountPortalBenefits: appRoute<
    AccountQuery & { benefitsPlanUuid?: string; coverageId?: string; coverageType?: string }
  >('/accounts/[accountUuid]/benefits'),
  viewAccountPortalTeamSupport: appRoute<AccountQuery>('/accounts/[accountUuid]/team-support'),
  viewAccountPortalResources: appRoute<AccountQuery>('/accounts/[accountUuid]/resources'),
  viewAccountPortalFAQs: appRoute<AccountQuery>('/accounts/[accountUuid]/faqs'),
  viewAccountPortalCountry: appRoute<AccountQuery>(`/accounts/[accountUuid]/country/[country]`),
  viewAccountPortalCustomization: appRoute<AccountQuery>('/accounts/[accountUuid]/customization'),
  viewAccountPortalRequiredNotices: appRoute<AccountQuery>('/accounts/[accountUuid]/required-notices'),
  viewAccountPortalBenjiAdmin: appRoute<AccountQuery & { tenantUuid?: string }>(`/accounts/[accountUuid]/benji-admin`),
  viewCreateBenjiKnowledgeBase: appRoute<AccountQuery & { tenantUuid?: string }>(
    `/accounts/[accountUuid]/benji-admin/create-knowledge-base`,
  ),

  // Employee web portal with mobile app admin
  viewAccountWebPortalOverview: appRoute<AccountQuery>('/accounts/[accountUuid]/web-portal/overview'),
  viewAccountWebPortalNewHire: appRoute<AccountQuery>('/accounts/[accountUuid]/web-portal/new-hire'),
  viewAccountWebPortalOpenEnrollment: appRoute<AccountQuery>('/accounts/[accountUuid]/web-portal/open-enrollment'),
  viewAccountWebPortalBenefits: appRoute<
    AccountQuery & { benefitsPlanUuid?: string; coverageId?: string; coverageType?: string }
  >('/accounts/[accountUuid]/web-portal/benefits'),
  viewAccountWebPortalTeamSupport: appRoute<AccountQuery>('/accounts/[accountUuid]/web-portal/team-support'),
  viewAccountWebPortalResources: appRoute<AccountQuery>('/accounts/[accountUuid]/web-portal/resources'),
  viewAccountWebPortalFAQs: appRoute<AccountQuery>('/accounts/[accountUuid]/web-portal/faqs'),
  viewAccountWebPortalCountry: appRoute<AccountQuery>(`/accounts/[accountUuid]/web-portal/country/[country]`),
};

const { LINKS } = getConfig();

export const newfrontAppRoutes = {
  applicationSummary: (clientId: number): string =>
    `${LINKS.LEGACY_DASH_APP}/admin/producer-dashboard/application-summary?clientId=${clientId}`,
  createLegacyClient: (): string => `${LINKS.LEGACY_DASH_APP}/admin/v2/client/create`,
  legacyAdminDashClientDetailsUrl: (clientId: number): string =>
    `${LINKS.LEGACY_DASH_APP}/admin/v2/clients/${clientId}`,
  legacyProducerDashClientDetailsUrl: (salesforceId: string): string =>
    `${LINKS.LEGACY_DASH_APP}/admin/producer-dashboard/clients/${salesforceId}`,
  legacyProposalsUrl: (clientId: number): string => `${LINKS.LEGACY_DASH_APP}/admin/clients/${clientId}/quotes`,
  legacyInvoiceModalUrl: (clientId: number): string =>
    `${LINKS.LEGACY_DASH_APP}/admin/v2/clients/${clientId}?showPayment=1`,
  viewApplications: (clientId: number): string => `${LINKS.LEGACY_DASH_APP}/admin/summary?clientId=${clientId}`,
};

/**
 * Routes to other apps like quoting, bulk import, etc.
 */
export const externalAppRoutes = {
  newQuotingPlan: (accountUuid: string): string => `/quoting/create/form?clientUuid=${accountUuid}`,
  quotingPlan: (quotingPlanUuid: string): string => `/quoting/${quotingPlanUuid}/plan`,
  bulkImportAccountServicingTeams: (): string => `/import/AccountEmployeeRoleMapping`,
  bulkImportCensus: (accountUuid: string): string => `/import/accounts/${accountUuid}/ClientEmployee`,
  bulkImportInsurableItem: (accountUuid: string, insurableItemType: string): string =>
    `/import/accounts/${accountUuid}/InsurableItem/${insurableItemType}`,
  bulkImportPolicyServicingTeams: (): string => `/import/PolicyRoleMapping`,
  claimDetails: (accountUuid: string, claimUuid: string): string =>
    `/claims-app/accounts/${accountUuid}/claims/${claimUuid}`,
  claimsByAccount: (accountUuid: string): string => `/claims-app/claims?clientUuid=${accountUuid}`,
  claimsV2ByAccount: (accountUuid: string): string => `/claims-app/accounts/${accountUuid}`,
  claimsByPolicy: (accountUuid: string, policyUuid: string): string =>
    `/claims-app/claims?clientUuid=${accountUuid}&policyUuid=${policyUuid}`,
  claimsV2ByPolicy: (accountUuid: string): string => `/claims-app/accounts/${accountUuid}`,
  clientDashboardLink: (accountUuid: string): string => `${LINKS.CLIENT_DASH_APP}/${accountUuid}`,
  conversionPoliciesToBor: (accountUuid: string, accountName: string): string =>
    `/conversion/policies-to-bor?accountUuid=${accountUuid}&accountName=${accountName}`,
  newClaim: (accountUuid: string, policyUuid: string): string =>
    `/claims-app/accounts/${accountUuid}/claims/new?policyUuid=${policyUuid}`,
  files: (policyUuid: string): string => `/files?id=${policyUuid}&type=policy`,
  reinstatePolicy: (policyUuid: string): string => `/admin/coverage/policies/${policyUuid}/reinstate`,
  createScheduledItem: (insurableItemUuid: string, policyUuid: string, accountUuid: string): string =>
    // eslint-disable-next-line max-len
    `/admin/coverage/scheduled-item/link?insurableItemUuid=${insurableItemUuid}&policyUuid=${policyUuid}&accountUuid=${accountUuid}`,
  editScheduledItem: (scheduledItemUuid: string, accountUuid: string): string =>
    `/admin/coverage/scheduled-item/${scheduledItemUuid}/edit?accountUuid=${accountUuid}`,
  unlinkScheduledItem: (scheduledItemUuid: string, accountUuid: string): string =>
    `/admin/coverage/scheduled-item/${scheduledItemUuid}/unlink?accountUuid=${accountUuid}`,
  viewCoverageGapAnalysisResult: (coverageGapAnalysisRequestUuid: string): string =>
    `/coverage-gap-analysis/result/${coverageGapAnalysisRequestUuid}`,
  viewFiles: (objectType: string, objectId: string): string => `/files/search?id=${objectId}&type=${objectType}`,
  viewUntriagedFiles: (objectType: string, objectId: string): string =>
    `/files/search?id=${objectId}&type=${objectType}&untriagedFiles=true`,
  viewPolicyChange: (accountUuid: string, policyChangeUuid: string): string =>
    `/accounts/${accountUuid}/policy-changes?tab=policy-history&legacyPolicyChangeUuid=${policyChangeUuid}`,
  viewPolicyChangeSummary: (workflowUuid: string): string =>
    `${LINKS.LEGACY_DASH_APP}/admin/workflow/policy-change/${workflowUuid}/status`,
  viewPolicyChangeInSSC: (salesforceCaseId: string): string =>
    `${LINKS.SERVICECLOUD_URL}/lightning/_classic/${salesforceCaseId}`,
  viewPolicyChangeInNewSSCUrl: (salesforceCaseId: string): string =>
    `${LINKS.NEW_SERVICECLOUD_URL}/lightning/_classic/${salesforceCaseId}`,
  viewInvoice: (invoiceUuid: string): string => `${LINKS.CLIENT_DASH_APP}/invoices/${invoiceUuid}`,
  viewInvoiceModal: (accountUuid: string, invoiceUuid: string): string =>
    `/accounts/${accountUuid}/invoices?invoiceUuid=${invoiceUuid}`,
  createNewInvoice: (accountUuid: string): string => `/payments/accounts/${accountUuid}/invoices/create-invoice`,
  editUnsentInvoice: (accountUuid: string, invoiceUuid: string): string =>
    `/payments/accounts/${accountUuid}/invoices/edit/${invoiceUuid}`,
  manageCertificates: (accountUuid: string): string => `/certificates/${accountUuid}/certificates`,
  hexAppSoi: (accountUuid: string): string => `${LINKS.HEX_SOI_APP}/latest?_account_uuid="${accountUuid}"`,
  hexAppLossRun: (accountUuid: string): string =>
    `https://app.hex.tech/6fc62e10-586d-466c-9272-b49abe3ecaed/app/b95da089-f8be-4a73-9dd8-9d237e948256/latest?_account_uuid="${accountUuid}"`,
  hexAppInvoiceProduct: (accountUuid: string): string =>
    `${LINKS.HEX_IP_APP}/latest?_account_id="${accountUuid}"&_run_button=true`,
  employeeWebPage: (employeeWebPortalUuid: string): string =>
    `${LINKS.CLIENT_DASH_APP}/employee/${employeeWebPortalUuid}`,
  employeeWebPageDraft: (employeeWebPortalUuid: string): string =>
    `${LINKS.CLIENT_DASH_APP}/employee/${employeeWebPortalUuid}/draft`,
};
